import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    Grid,
    Stack,
    Typography,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    TextField,
    Card,
    Link as MuiLink,
    Alert,
    AlertTitle,
} from "@mui/material";
import { LinqRequestStatusChip } from "./LinqRequestStatusChip";
import DialogBox from "components/lib-ui/DialogBox";
import { GlobalAppDataStorageService } from "infra/storage";
import getErrorMessage from "assets/data/errors.json";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useLinqRequests } from "hooks/useLinqRequests";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setIsDialogOpen } from "reducers/linq-requests/linqRequestsSlice";
import { Link } from "react-router-dom";

const applicationStatusOptions = () =>
    GlobalAppDataStorageService?.getLinqStatuses();

const REJECTED_APPLICATION_ID = 3;

const rowStyles = {
    height: "25px",
    diplay: "flex",
    alignItems: "center",
};

const LinqApplicationDialog = () => {
    const { isDialogOpen, selectedLinqApplication, filters, resultLength } =
        useSelector((state) => state.linqRequestsSlice);
    const { control, register, reset, watch, handleSubmit, errors } = useForm();

    const { updateLinqRequestAndGetList, isUpdateAndGetLoading } =
        useLinqRequests();
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setIsDialogOpen(false));
    };

    const watchApplicationStatus = watch("linq_status_id");
    const isApplicationRejected =
        watchApplicationStatus === REJECTED_APPLICATION_ID;

    useEffect(() => {
        if (isDialogOpen) {
            reset({
                linq_status_id: selectedLinqApplication?.status || "",
                reason: selectedLinqApplication?.reason || "",
                linq_request_id: selectedLinqApplication?.id || "",
            });
        }
    }, [isDialogOpen, selectedLinqApplication, reset]);

    const submitForm = async (data) => {
        const updateParams = data;
        const getParams = { ...filters, page_size: resultLength } || {};

        if (!isApplicationRejected) delete data.reason;

        await updateLinqRequestAndGetList(updateParams, getParams);

        if (!isUpdateAndGetLoading) handleClose(true);
    };

    const deriveCompanyName = () => {
        const company = selectedLinqApplication?.company;
        const companyId = selectedLinqApplication?.company_id;
        return (
            <Link
                to={`/company/view/${companyId}`}
                rel="noopener noreferrer"
                target="_blank"
                title={`Go to ${company}'s page`}
            >
                <MuiLink>{`${company}`}</MuiLink>
            </Link>
        );
    };

    const deriveCandidateName = () => {
        const candidateName = selectedLinqApplication?.candidate_name;
        const candidateId = selectedLinqApplication?.candidate_id;
        return candidateName ? (
            <Link
                to={`/candidate/view/${candidateId}`}
                rel="noopener noreferrer"
                target="_blank"
                title={`Go to ${candidateName}'s page`}
            >
                <MuiLink>{candidateName}</MuiLink>
            </Link>
        ) : (
            "-"
        );
    };

    return (
        <DialogBox
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={handleClose}
            title="Linq Application Status Change"
            floatCancel={true}
            showActionButtons={false}
        >
            <Box sx={{ mt: "1rem", mb: "3rem" }}>
                <Card variant="outlined" sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Stack spacing={1} justifyContent="center">
                                <Typography sx={rowStyles} variant="subtitle2">
                                    Linq Request Id
                                </Typography>
                                <Typography sx={rowStyles} variant="subtitle2">
                                    Candidate name
                                </Typography>
                                <Typography sx={rowStyles} variant="subtitle2">
                                    Company
                                </Typography>
                                <Typography sx={rowStyles} variant="subtitle2">
                                    Application Status
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={8}>
                            <Stack
                                spacing={1}
                                alignItems="start"
                                justifyContent="center"
                            >
                                <Typography
                                    sx={rowStyles}
                                    fontWeight="bold"
                                    variant="body1"
                                >
                                    {selectedLinqApplication?.id || "-"}
                                </Typography>
                                <Box sx={rowStyles}>
                                    {deriveCandidateName()}
                                </Box>
                                <Box sx={rowStyles}>{deriveCompanyName()}</Box>
                                <Box sx={rowStyles}>
                                    <LinqRequestStatusChip
                                        status={selectedLinqApplication?.status}
                                    />
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card>
            </Box>

            <Alert severity="warning" >
                <AlertTitle>Attention</AlertTitle>
                {deriveCompanyName()}  will be notified about this change. Are you sure you want to proceed?
            </Alert>

            <Box
                component="form"
                onSubmit={handleSubmit(submitForm)}
                sx={{ minWidth: "100%", mt: "3rem" }}
            >
                <Stack gap={3}>
                    <Controller
                        name="linq_status_id"
                        defaultValue={selectedLinqApplication?.status}
                        control={control}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel>Application Status</InputLabel>
                                <Select {...field} label="Application Status">
                                    {applicationStatusOptions().map(
                                        (option) => (
                                            <MenuItem
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    />
                    {isApplicationRejected && (
                        <TextField
                            fullWidth
                            required
                            name="reason"
                            defaultValue={selectedLinqApplication?.reason}
                            {...register("reason", {
                                required: getErrorMessage.generic.required,
                            })}
                            multiline
                            minRows={5}
                            error={Boolean(errors?.reason)}
                            helperText={errors?.reason?.message || ""}
                            label="Reason"
                        />
                    )}

                    <input
                        defaultValue={selectedLinqApplication?.id}
                        type="hidden"
                        {...register("linq_request_id")}
                        name="linq_request_id"
                    />
                </Stack>

                <Stack
                    direction="row"
                    mt={4}
                    spacing={2}
                    justifyContent="flex-end"
                >
                    <LoadingButton
                        color="primary"
                        type="submit"
                        loading={isUpdateAndGetLoading}
                        loadingPosition="start"
                        startIcon={<SaveIcon />}
                    >
                        Save changes
                    </LoadingButton>
                </Stack>
            </Box>
        </DialogBox>
    );
};

export default LinqApplicationDialog;
