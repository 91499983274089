import { useState, useEffect, useCallback, useRef } from "react";
import {
    Box,
    Stack,
    Checkbox,
    TextField,
    FormControlLabel,
    Autocomplete,
    Tooltip,
    Chip,
} from "@mui/material";
import { alpha } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import DangerousIcon from "@mui/icons-material/Dangerous";
import IconBtn from "components/lib-ui/IconBtn";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import DiagonalPatternBox from "./DiagonalPatternBox";
import SocialMediaURL from "services/SocialMediaURL.service";
import GeneralUtilityService from "services/GeneralUtilityService";

import useFetchCities from "hooks/useFetchCities";

const renderChips = (chips, color = "primary", variant = "filled") => {
    if (!chips || !chips?.length || typeof chips !== "object") return;

    return (
        <Stack direction="row" flexWrap="wrap" gap={1}>
            {chips?.map((chip, index) => (
                <Chip
                    key={index}
                    color={color}
                    label={chip.name}
                    variant={variant}
                />
            ))}
        </Stack>
    );
};

const renderOutputParsedValue = (value, typeOfValue, renderType) => {
    if (value == null) return value;

    if (renderType === "skills") {
        return renderChips(value, "skillChipColor");
    }

    // Handle object that is not of the above types
    if (typeOfValue === "object" && typeOfValue !== "array") {
        return value.name || null;
    }

    // Handle array of objects
    if (typeOfValue === "array") {
        return (
            value
                .map((item) => (typeof item === "object" ? item.name : item))
                .filter(Boolean) || null
        );
    }

    // Handle other types (string, number, etc.)
    return value;
};

const shouldBeMultiple = (value, renderType) => {
    if (GeneralUtilityService.getTypeOfValue(value) === "array") {
        return true;
    }

    if (renderType === "skills") {
        return true;
    }

    return false;
};

export const InlineFieldEditor = ({
    type,
    defaultValue,
    fieldName,
    groupChecked = true,
    hookFormSetValue = () => {},
    options = defaultValue || [],
    freeSolo = true,
    disabled = false,
    diffView = false,
    reduceObjToIds = false,
    index,
}) => {
    let inputRef = useRef(null);
    const data = useSelector(
        (state) => state.parsedProfileDataSlice.editableData
    );
    const theme = useTheme();
    const [value, setValue] = useState(() => {
        if (Array.isArray(defaultValue) && !defaultValue?.length) return "";
        return defaultValue;
    });
    const [tempValue, setTempValue] = useState(value);

    const [isFormValid, setIsFormValid] = useState(true);
    const [isChecked, setIsChecked] = useState(() => defaultValue || "");

    const [isEditMode, setIsEditMode] = useState(false);
    const { control } = useForm();
    const { fetchCitiesWithoutCountry } = useFetchCities();

    const fieldNameArr = fieldName.split("_");
    const getFieldNameLastItem = fieldNameArr[fieldNameArr.length - 1];
    const typeOfDefaultValue =
        GeneralUtilityService.getTypeOfValue(defaultValue);

    const renderDataViewer = renderOutputParsedValue(
        value,
        typeOfDefaultValue,
        getFieldNameLastItem
    );

    const isMultiple = shouldBeMultiple(
        typeOfDefaultValue,
        getFieldNameLastItem
    );

    const isUnmatched = () => {
        if (typeof value === "object" && !value?.id) {
            if (getFieldNameLastItem === "city") {
                return "city";
            } else {
                return true;
            }
        }
        return false;
    };

    const newOrExistingData = diffView ? data?.existingUserData : data;

    const diffValue = newOrExistingData?.generalInfo[fieldName];

    const generateLinkedInUrlOrValue = () => {
        if (!isLinkedinField) return diffValue;
        if (!isChecked && !diffView && isLinkedinField) return diffValue;
        const composeLinkedin = data.existingUserData.generalInfo[fieldName] ? 
            `${SocialMediaURL.LINKEDIN_PROFILE_BASE_URL}/${data.existingUserData.generalInfo[fieldName]}` 
            : null;
        const outputValue = isLinkedinField ? composeLinkedin : diffValue;

        return outputValue;
    };

    const unmatchedResult = isUnmatched();
    const existingUserData = !!data.existingUserData;
    const isLinkedinField = fieldName === "linkedinProfile";
    const typeProperties = {
        textarea: { isTextarea: true },
        phone: { isPhone: true, isNumber: true },
        autocomplete: { isAutoComplete: true },
        number: { isNumber: true },
        email: { isEmail: true },
    };
    const autoCompleteTypes = {
        skills: { isSkills: true },
    };
    const {
        isTextarea = false,
        isPhone = false,
        isAutoComplete = false,
        isNumber = false,
    } = typeProperties[type] || {};

    const {
        // eslint-disable-next-line no-unused-vars
        isSkills = false,
    } = autoCompleteTypes[getFieldNameLastItem] || {};

    if (isPhone) type = "number";

    const [cityOptions, setCityOptions] = useState([]);

    useEffect(() => {
        if (getFieldNameLastItem === "city") {
            fetchCitiesWithoutCountry(defaultValue.name).then((data) => {
                if (Array.isArray(data)) {
                    setCityOptions(data);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCitiesBasedOnInput = GeneralUtilityService.debounce(
        async (inputValue) => {
            if (!inputValue) return;
            const newCityOptions = await fetchCitiesWithoutCountry(inputValue);
            setCityOptions(newCityOptions);
        },
        1000
    );

    const modifyOptions = (options) => {
        if (getFieldNameLastItem === "city") {
            return cityOptions || [];
        }
        return options || [];
    };

    useEffect(() => {
        if (getFieldNameLastItem === "city") {
            fetchCitiesWithoutCountry(defaultValue.name).then((data) => {
                setCityOptions(data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, getFieldNameLastItem]);

    const modifiedOptionsList = modifyOptions(options);

    const styles = {
        wrapper: {
            width: "100%",
            minHeight: "38px",
            display: "grid",
            gridTemplateColumns: diffView ? "1fr 38px 1fr" : "38px 1fr",
            padding: 0,
            ":hover [type='button']": {
                opacity: 1,
                pointerEvents: "auto",
                background: "white",
            },
        },
        text: {
            display: "inline",
            lineHeight: "inherit",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",
        },
        disabledText: {
            color: theme.palette.error.main,
            background: alpha(theme.palette.error.main, 0.1),
            textDecoration: "line-through",
        },
        disabledBox: {
            background: alpha(theme.palette.error.main, 0.1),
            ".MuiChip-filled": {
                background: alpha(theme.palette.error.main, 1),
                color: theme.palette.gray.light,
                textDecoration: "line-through",
            },
        },
        successText: {
            color: theme.palette.success.main,
            background: alpha(theme.palette.success.main, 0.2),
        },
        successBox: { background: alpha(theme.palette.success.main, 0.1) },
    };

    useEffect(() => {
        setIsChecked(groupChecked);
    }, [groupChecked]);

    useEffect(() => {
        if (!value || !tempValue) setIsChecked(false);
        hookFormSetValue(fieldName, defaultValue);
        checkFreeSoloValidity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (inputRef.current) {
            setIsFormValid(inputRef.current.checkValidity());
        }
        checkFreeSoloValidity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempValue]);

    const handleEdit = useCallback(() => {
        checkFreeSoloValidity();
        setTempValue(value);
        setIsEditMode(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleCancelEdit = () => {
        setTempValue(null);
        setIsEditMode(false);
    };

    const handleCheckboxChange = () => {
        if (!value) return;
        setIsChecked((checked) => !checked);
    };

    useEffect(() => {
        let newValue;
        if (!isChecked) newValue = generateLinkedInUrlOrValue();
        if (isChecked) newValue = value;
        if (!isChecked && !diffView) newValue = "";
        hookFormSetValue(fieldName, newValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked]);

    const checkFreeSoloValidity = () => {
        if (freeSolo || !isAutoComplete) return;

        const validateOptions = (options) => {
            options.some((option) => {
                if (Array.isArray(tempValue)) {
                    return tempValue.filter(
                        (eachTemp) => eachTemp?.name === option?.name
                    );
                }
                return option?.name === tempValue?.name;
            });
        };
        let isValidOption = false;

        if (getFieldNameLastItem === "city" || isSkills) {
            isValidOption = true;
        } else {
            isValidOption = validateOptions(options);
        }

        if (isValidOption) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    };

    const handleSaveChange = () => {
        setValue(reduceObjToIds ? tempValue.id : tempValue);
        if (!isChecked) {
            hookFormSetValue(fieldName, generateLinkedInUrlOrValue());
        }
        if (isChecked) {
            hookFormSetValue(fieldName, tempValue);
        }
        setTempValue(null);
        setIsEditMode(false);
    };

    const handleInputBlur = (event) => {
        const value = event.target.value;
        if (value) setIsChecked(true);
        if (!value) setIsChecked(false);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
            }}
        >
            <Stack
                component="div"
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={styles.wrapper}
            >
                {diffView &&
                    (existingUserData &&
                    Boolean(
                        data?.existingUserData?.generalInfo?.[fieldName]
                    ) ? (
                        <Box
                            sx={{
                                ml: 2,
                                ...styles.text,
                                ...(isChecked && styles.disabledText),
                                ...(!isChecked && styles.successText),
                            }}
                        >
                            {generateLinkedInUrlOrValue()}
                        </Box>
                    ) : (
                        <DiagonalPatternBox />
                    ))}

                <FormControlLabel
                    sx={{ m: 0 }}
                    control={
                        <Checkbox
                            checked={disabled ? true : !!isChecked}
                            onChange={handleCheckboxChange}
                            size="small"
                            disabled={renderDataViewer === null || disabled}
                        />
                    }
                />

                <Stack direction="row">
                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        {isEditMode ? (
                            <Box
                                onSubmit={(event) => {
                                    if (!value?.length && !freeSolo) return;
                                    event.preventDefault();
                                }}
                                onKeyDown={(event) => {
                                    if (!value?.name?.length && !freeSolo)
                                        return;
                                    if (
                                        (event.ctrlKey || event.shiftKey) &&
                                        event.key === "Enter"
                                    )
                                        return;
                                    if (event.key === "Enter")
                                        handleSaveChange();
                                }}
                            >
                                {isAutoComplete ? (
                                    <Controller
                                        name={fieldName}
                                        control={control}
                                        defaultValue={value || []}
                                        render={({ field }) => (
                                            <Autocomplete
                                                id={fieldName}
                                                multiple={isMultiple}
                                                fullWidth
                                                freeSolo
                                                disableClearable={true}
                                                disabled={disabled}
                                                autoComplete
                                                options={modifiedOptionsList}
                                                getOptionLabel={(option) =>
                                                    option?.name ||
                                                    option?.value ||
                                                    ""
                                                }
                                                isOptionEqualToValue={(
                                                    option,
                                                    value
                                                ) => {
                                                    return (
                                                        option.id === value.id
                                                    );
                                                }}
                                                {...field}
                                                onChange={(_, newValue) => {
                                                    field.onChange(newValue);
                                                    setTempValue(newValue);
                                                    hookFormSetValue(
                                                        fieldName,
                                                        reduceObjToIds
                                                            ? newValue.id
                                                            : newValue
                                                    );
                                                    checkFreeSoloValidity();
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        autoFocus
                                                        inputRef={inputRef}
                                                        fullWidth
                                                        color="focus"
                                                        variant="standard"
                                                        onChange={(event) => {
                                                            const newValue = {
                                                                name: event
                                                                    .target
                                                                    .value,
                                                                id: null,
                                                            };
                                                            setTempValue(
                                                                newValue
                                                            );
                                                            hookFormSetValue(
                                                                fieldName,
                                                                reduceObjToIds
                                                                    ? newValue.id
                                                                    : newValue
                                                            );
                                                            fetchCitiesBasedOnInput(
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        value={tempValue ?? ""}
                                                        name="active-field"
                                                        sx={{
                                                            paddingTop: "3px",
                                                            color: "red",
                                                            ".MuiInputBase-root":
                                                                {
                                                                    padding:
                                                                        "5px",
                                                                },
                                                            input: {
                                                                padding: "0",
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                ) : (
                                    <TextField
                                        autoFocus
                                        inputRef={inputRef}
                                        fullWidth
                                        disabled={disabled}
                                        color="focus"
                                        {...(isTextarea && { multiline: true })}
                                        type={type || "textarea"}
                                        inputProps={
                                            isNumber ? { step: "0.1" } : {}
                                        }
                                        onChange={(event) => {
                                            setTempValue(event.target.value);
                                            setIsFormValid(
                                                event.target.checkValidity()
                                            );
                                            if (isChecked) {
                                                hookFormSetValue(
                                                    fieldName,
                                                    event.target.value
                                                );
                                            }
                                        }}
                                        onBlur={handleInputBlur}
                                        variant="standard"
                                        value={
                                            typeof tempValue === "object"
                                                ? tempValue?.name
                                                : tempValue
                                        }
                                        name="active-field"
                                        sx={{
                                            paddingTop: "3px",
                                            color: "red",
                                            ".MuiInputBase-root": {
                                                padding: "5px",
                                            },
                                            input: { padding: "0" },
                                        }}
                                    />
                                )}
                            </Box>
                        ) : (
                            <Box py="9px">
                                <input
                                    name={fieldName}
                                    disabled={!isChecked}
                                    type="hidden"
                                    value={value || ""}
                                />
                                <Stack
                                    spacing={1}
                                    alignItems="flex-start"
                                    direction="row"
                                    sx={{
                                        ...(!isChecked && styles.disabledBox),
                                        ...(isChecked && styles.successBox),
                                    }}
                                    onDoubleClick={handleEdit}
                                >
                                    {unmatchedResult &&
                                        isChecked &&
                                        Boolean(value.name?.length) && (
                                            <Tooltip
                                                title={`Warning! No matched option.${
                                                    unmatchedResult === "city"
                                                        ? " Will be deleted on save"
                                                        : " Will be created on save "
                                                }`}
                                            >
                                                {unmatchedResult === "city" ? (
                                                    <DangerousIcon
                                                        fontSize="small"
                                                        color="error"
                                                    />
                                                ) : (
                                                    <ErrorIcon
                                                        fontSize="small"
                                                        color="disabled"
                                                    />
                                                )}
                                            </Tooltip>
                                        )}
                                    <Box
                                        sx={{
                                            ...styles.text,
                                            ...(isChecked &&
                                                styles.successText),
                                            ...(!isChecked &&
                                                styles.disabledText),
                                        }}
                                    >
                                        {renderDataViewer}
                                    </Box>
                                </Stack>
                            </Box>
                        )}
                    </Box>

                    <Stack
                        pl={1}
                        justifyContent="flex-end"
                        sx={{
                            position: "sticky",
                            top: "20px",
                            marginLeft: "auto",
                        }}
                    >
                        {isEditMode ? (
                            <Stack
                                spacing={1}
                                pt="3px"
                                direction="row"
                                justifyContent="flex-end"
                            >
                                <IconBtn
                                    sx={{ border: "unset" }}
                                    icon={ClearOutlinedIcon}
                                    onClick={handleCancelEdit}
                                    iconStyles={{ color: "error.main" }}
                                />
                                <IconBtn
                                    disabled={!isFormValid}
                                    sx={{ border: "unset" }}
                                    icon={CheckOutlinedIcon}
                                    onClick={handleSaveChange}
                                    iconStyles={{
                                        color: isFormValid
                                            ? "success.main"
                                            : "gray",
                                        cursor: isFormValid
                                            ? "inherit"
                                            : "not-allowed",
                                    }}
                                />
                            </Stack>
                        ) : (
                            <Stack
                                spacing={1}
                                direction="row"
                                justifyContent="flex-end"
                            >
                                <IconBtn
                                    sx={{
                                        opacity: 0,
                                        pointerEvents: "none",
                                        marginLeft: "auto",
                                        border: "unset",
                                        background: "white",
                                    }}
                                    icon={EditOutlinedIcon}
                                    onClick={handleEdit}
                                    disabled={disabled}
                                />
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
};

export default InlineFieldEditor;
